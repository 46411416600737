import * as React from "react";

import Layout from "../../components/Layout";
import BlogRoll from "../../components/BlogRoll";
export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <div className="full-width-image-container margin-top-0">
          <h1 className="has-text-weight-bold is-size-1">
            <img
              src="      https://media4.giphy.com/media/bSvPZhiI0JuH6/giphy.gif?cid=790b76117f3473ce066ee47c5854ccdc84dbfb2556527596&rid=giphy.gif&ct=s"
              style={{ objectFit: "contain" }}
            />
            Latest News From the Court
            <img
              src="https://media2.giphy.com/media/egdRKjEZthe2A/giphy.gif?cid=446e6413u5mjedalz6g1094guy00prjt8098p0f3bgn08hzx&rid=giphy.gif&ct=s"
              style={{ objectFit: "contain" }}
            />
          </h1>
        </div>
        <section className="section">
          <div className="container">
            <div className="content">
              <BlogRoll />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
